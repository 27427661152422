import { Link } from 'gatsby';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { themeLogo } from '../../../state/theme';
import { AuthContext } from '../../../contexts/AuthContext';

export const Logo = ({ className }) => {
  const { user } = useContext(AuthContext);
  const logoVariant = useRecoilValue(themeLogo);

  const isLogoLightTheme = logoVariant === 'light';

  return (
    <h1 className={className}>
      <Link to={user ? '/moje/nastenka' : '/'} data-testid="logo">
        <span
          className={`mr-1 sm:text-2xl uppercase font-extrabold tracking-widest ${
            isLogoLightTheme ? 'text-primary' : 'text-secondary'
          }`}
        >
          osobní
        </span>
        <span
          className={`sm:text-2xl uppercase tracking-widest font-light ${
            isLogoLightTheme ? 'text-white' : 'text-primary'
          }`}
        >
          skóre
        </span>
      </Link>
    </h1>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};
