require('whatwg-fetch');
const React = require('react');
const useSWR = require('swr');
const { RecoilRoot } = require('recoil');
const { QueryParamProvider } = require('use-query-params');
const { globalHistory } = require('@reach/router');
const { withLDProvider } = require('launchdarkly-react-client-sdk');

const { AuthProvider } = require('./src/contexts/AuthContext');
const { ErrorProvider } = require('./src/contexts/ErrorContext');
const ErrorBoundary = require('./src/components/ErrorBoundary').default;
const { fetcher } = require('./src/utilities/fetcher');
const api = require('./src/utilities/api').default;

require('./src/styles/global.css');

const SWRConfig = useSWR.SWRConfig;

const AppWithFeatureFlags = ({ element }) => {
  const [scoreEnvId, setScoreEnvId] = React.useState(
    process.env.GATSBY_LAUNCHDARKLY_CLIENT_KEY
  );

  React.useEffect(() => {
    if (!scoreEnvId) {
      api.loadConfiguration().then((launchDarkly) => {
        setScoreEnvId(launchDarkly.loadedEnvId);
      });
    }
  }, [scoreEnvId]);

  if (!scoreEnvId) {
    return;
  }

  const App = () => (
    <SWRConfig value={{ fetcher }}>
      <QueryParamProvider path="/" reachHistory={globalHistory}>
        <RecoilRoot>
          <ErrorBoundary>
            <ErrorProvider>
              <AuthProvider>{element}</AuthProvider>
            </ErrorProvider>
          </ErrorBoundary>
        </RecoilRoot>
      </QueryParamProvider>
    </SWRConfig>
  );

  const LDProvider = withLDProvider({
    clientSideID: scoreEnvId,
  })(App);

  return <LDProvider />;
};

const Wrapper = ({ element }) => {
  return <AppWithFeatureFlags element={element} />;
};

exports.wrapRootElement = Wrapper;
