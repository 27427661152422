import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

export const Button = ({
  children,
  className = '',
  disabled,
  href,
  target,
  to,
  color,
  variant,
  border,
  size,
  ...rest
}) => {
  const isPrimary = color === 'primary';
  const isSecondary = color === 'secondary';
  const hasBorder = !!border;

  let css = `inline-block text-center select-none tracking-widest transition-colors duration-200 ease-out focus:outline-none ${className}`;

  css = isPrimary ? `${css} text-white` : css;
  css =
    isPrimary && !disabled
      ? `${css} bg-primary hover:bg-primary hover:shadow-button-primary`
      : css;
  css = isSecondary ? `${css} text-secondary bg-white` : css;
  css =
    isSecondary && !disabled
      ? `${css} hover:bg-secondary hover:text-white hover:shadow-button-secondary`
      : css;
  css = isPrimary || isSecondary ? `${css} font-bold uppercase` : css;
  css = color === 'transparent' ? `${css} bg-transparent` : css;
  css = variant === 'rounded' ? `${css} rounded-full` : css;
  css = size === 'small' ? `${css} py-1 px-2 sm:py-2 sm:px-3 text-xs` : css;
  css = size === 'normal' ? `${css} py-3 px-4 sm:py-4 sm:px-6 text-base` : css;
  css = hasBorder ? `${css} border-solid` : css;
  css = border === 'normal' ? `${css} border-3` : css;
  css = border === 'thin' ? `${css} border-2` : css;
  css = hasBorder && !isPrimary && !isSecondary ? `${css} border-white` : css;
  css = hasBorder && isPrimary && !disabled ? `${css} border-primary` : css;
  css = hasBorder && isSecondary && !disabled ? `${css} border-secondary` : css;
  css = disabled
    ? `${css} text-white bg-grey-2 border-grey-2 cursor-not-allowed`
    : css;

  const tabIndex = disabled ? -1 : 0;

  if (href) {
    const rel = target && 'noopener noreferrer';

    return (
      <a
        href={href}
        className={css}
        tabIndex={tabIndex}
        target={target}
        rel={rel}
        {...rest}
      >
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} className={css} tabIndex={tabIndex} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <button className={css} tabIndex={tabIndex} disabled={disabled} {...rest}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  color: 'primary',
  variant: 'rounded',
  size: 'normal',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'transparent']),
  variant: PropTypes.oneOf([null, 'rounded']),
  border: PropTypes.oneOf(['thin', 'normal']),
  size: PropTypes.oneOf(['small', 'normal']),
};
