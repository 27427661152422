import React from 'react';
import { useRecoilValue } from 'recoil';

import { themeFooter } from '../../../state/theme';
import privacyPolicy from '../../../assets/documents/zasady-ochrany-osobnich-udaju.pdf';
import terms from '../../../assets/documents/podminky-sluzby.pdf';

export const Footer = () => {
  const theme = useRecoilValue(themeFooter);

  return (
    <footer
      className={`absolute left-0 right-0 pb-5 bottom-0 text-center ${
        theme === 'light' ? 'text-white' : ''
      }`}
    >
      <div>
        © {new Date().getFullYear()} Air Bank a.s., člen skupiny PPF,
        poskytovatel služby Osobní skóre, je zapsaná u&nbsp;rejstříkového soudu
        v &nbsp;Praze — spisová značka B&nbsp;16013
      </div>
      <div>
        <a
          href={privacyPolicy}
          className="block sm:inline underline whitespace-nowrap"
          target="_blank"
          rel="noreferrer"
        >
          Zásady ochrany osobních údajů
        </a>
        <span className="hidden sm:inline mx-2">|</span>
        <a
          href={terms}
          className="block sm:inline underline whitespace-nowrap"
          target="_blank"
          rel="noreferrer"
        >
          Podmínky služby Osobní skóre
        </a>
        <span className="hidden sm:inline mx-2">|</span>
        <button
          // eslint-disable-next-line no-undef
          onClick={() => OneTrust?.ToggleInfoDisplay?.()}
          className="block sm:inline underline whitespace-nowrap"
          target="_blank"
          rel="noreferrer"
          aria-label="Nastavení cookies"
        >
          Nastavení cookies
        </button>
      </div>
    </footer>
  );
};
