import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from '../../../components/shared/Layout';
import { Seo } from '../../../components/shared/Seo';
import { Button } from '../../../components/shared/Button';

export const ErrorPage = ({
  className,
  seoTitle,
  title,
  description,
  onClick,
}) => {
  return (
    <Layout>
      <Seo title={seoTitle} />
      <div
        className={
          className
            ? undefined
            : 'font-sans pt-screen/7 md:pt-screen/14 text-center relative'
        }
      >
        <h1
          className="m-0 mb-1 md:mb-4 leading-none text-navyblue text-ups-title font-bold uppercase"
          data-testid="error-page-title"
        >
          {title}
        </h1>
        <h2
          className="m-0 mb-8 md:mb-16 font-light text-2xl text-grey-ups-description"
          data-testid="error-page-description"
        >
          {description}
        </h2>

        <Button
          to="/"
          onClick={onClick}
          className="mx-auto"
          data-testid="error-page-osobniskore-button"
        >
          Přejít na osobniskore.cz
        </Button>
      </div>
    </Layout>
  );
};

ErrorPage.propTypes = {
  className: PropTypes.string,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};
